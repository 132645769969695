<template>
    <v-card
            width="240"
            outlined
    >
        <v-card-text style="margin-top: 20px">
        <primaryButton
      v-for="key in keys"
      :key="key"
      @click="press(key)"
      :class="keyTheme"
      :label="key"
      type="button"
      :color=buttColor
      elevation="1"
      :id="'padKey_'+key"
    ></primaryButton>
    <primaryButton
            :class="keyTheme"
            @click="clear()"
            icon="mdi-arrow-left"
            type="button"
            :color=buttColor
            elevation="1"
    ></primaryButton>
    <primaryButton
            :class="keyTheme"
            @click="clear('all')"
            label="C"
            type="button"
            :color=buttColor
            elevation="1"
    >C</primaryButton>
        </v-card-text>
   </v-card>
</template>

<script>

import primaryButton from "@/commonComponents/primaryButton.vue";
import { validateOTP } from "@/js/testHelpers.js";

export default {
  props: ['selfValue','result'],
  components: {
    primaryButton,
  },
  data() {
    return {
      value: '',
      keys: [...Array(10).keys()],
      keyTheme: 'btn-keyboard',
      buttColor: '#2b5593',
    };
  },
  methods: {
 /*   shuffle() {
      this.keys = _.shuffle(this.keys);
    },*/
    press(key) {
      this.value = `${this.value}${key}`;
      //console.log(key);
      //this.shuffle();
    },
    clear(type) {
      if (type === 'all') this.value = '';
      else this.value = this.value.substring(0, this.value.length - 1);
    },
  },
  watch: {
    value() {
      this.$emit('pressed', this.value);
      validateOTP(this.value,this);
     // this.$parent.$refs.numInput.$el.change();
       //this.$emit('input', this.$parent.$refs.numInput.$el.oninput);
     // console.log(this.$parent.$refs.numInput);
    },
    selfValue() {
      this.value = this.selfValue;
    },
  },

};
</script>

<style scoped>
    .btn-keyboard {
        min-width: 60px  !important;
        margin: 1px  !important;
    }
</style>
